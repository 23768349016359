import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  EditableMarkdown,
  SponsorsSection,
} from "@app/components";

export interface FaqsPageData {
  title: string;
  items: { q: string; a: string }[];
}

export const FaqsPage = ({
  data,
  isEdit,
}: {
  data: FaqsPageData;
  isEdit: boolean;
}) => {
  if (!isEdit) {
    data = (data as any).content.childMarkdownRemark.frontmatter;
  }

  const faqItems: AccordionItem[] = data.items.map((f) => {
    return {
      header: f.q,
      body: <EditableMarkdown isEdit={isEdit} markdown={f.a} />,
    };
  });

  return (
    <>
      <section className="py-5">
        <Container>
          <Row className="justify-content-center">
            {/* intro */}
            {data.title && (
              <Col md={12} className="text-center mb-5">
                <h1 className="text-primary">{data.title}</h1>
              </Col>
            )}

            {/* faq */}
            {faqItems.length > 0 && (
              <Col md={10}>
                <Accordion items={faqItems} />
              </Col>
            )}
          </Row>
        </Container>
      </section>

      {/* sponsors */}
      {!isEdit && <SponsorsSection />}
    </>
  );
};

export default FaqsPage;

export const query = graphql`
  query {
    content: file(
      sourceInstanceName: { eq: "content" }
      relativeDirectory: { eq: "pages" }
      name: { eq: "faqs" }
    ) {
      childMarkdownRemark {
        frontmatter {
          seo_title
          seo_description
          title
          items {
            q
            a
          }
        }
      }
    }
  }
`;
